
// @ts-nocheck
export const availableAreas = [
    "userRegistration",
    "vendor",
    "error",
    "pass",
    "passList",
    "linkDisplayer",
    "receiptDisplayer",
    "common", 
];
export async function getResource(area: string, lng: string) {
  // dynamic import import will break the react magic and bundle won't be created.
  // it's required to be a switch (or if else) to let react create bundle with these files

  switch (area) {

   case "userRegistration":
      switch (lng) {
        case "ar":
          return (await import("./userRegistration.ar.yml")).default;
        
        case "cs":
          return (await import("./userRegistration.cs.yml")).default;
        
        case "ca":
          return (await import("./userRegistration.ca.yml")).default;
        
        case "da":
          return (await import("./userRegistration.da.yml")).default;
        
        case "de":
          return (await import("./userRegistration.de.yml")).default;
        
        case "el":
          return (await import("./userRegistration.el.yml")).default;
        
        case "en":
          return (await import("./userRegistration.en.yml")).default;
        
        case "es":
          return (await import("./userRegistration.es.yml")).default;
        
        case "fr":
          return (await import("./userRegistration.fr.yml")).default;
        
        case "it":
          return (await import("./userRegistration.it.yml")).default;
        
        case "ja":
          return (await import("./userRegistration.ja.yml")).default;
        
        case "ko":
          return (await import("./userRegistration.ko.yml")).default;
        
        case "ms":
          return (await import("./userRegistration.ms.yml")).default;
        
        case "nl":
          return (await import("./userRegistration.nl.yml")).default;
        
        case "no":
          return (await import("./userRegistration.no.yml")).default;
        
        case "pl":
          return (await import("./userRegistration.pl.yml")).default;
        
        case "pt":
          return (await import("./userRegistration.pt.yml")).default;
        
        case "ru":
          return (await import("./userRegistration.ru.yml")).default;
        
        case "sv":
          return (await import("./userRegistration.sv.yml")).default;
        
        case "th":
          return (await import("./userRegistration.th.yml")).default;
        
        case "tr":
          return (await import("./userRegistration.tr.yml")).default;
        
        case "zh":
          return (await import("./userRegistration.zh.yml")).default;
        
        case "zh-Hans":
          return (await import("./userRegistration.zh-Hans.yml")).default;
        
        case "zh-Hant":
          return (await import("./userRegistration.zh-Hant.yml")).default;
        
        default:
          return {};
      }
   case "vendor":
      switch (lng) {
        case "ar":
          return (await import("./vendor.ar.yml")).default;
        
        case "cs":
          return (await import("./vendor.cs.yml")).default;
        
        case "ca":
          return (await import("./vendor.ca.yml")).default;
        
        case "da":
          return (await import("./vendor.da.yml")).default;
        
        case "de":
          return (await import("./vendor.de.yml")).default;
        
        case "el":
          return (await import("./vendor.el.yml")).default;
        
        case "en":
          return (await import("./vendor.en.yml")).default;
        
        case "es":
          return (await import("./vendor.es.yml")).default;
        
        case "fr":
          return (await import("./vendor.fr.yml")).default;
        
        case "it":
          return (await import("./vendor.it.yml")).default;
        
        case "ja":
          return (await import("./vendor.ja.yml")).default;
        
        case "ko":
          return (await import("./vendor.ko.yml")).default;
        
        case "ms":
          return (await import("./vendor.ms.yml")).default;
        
        case "nl":
          return (await import("./vendor.nl.yml")).default;
        
        case "no":
          return (await import("./vendor.no.yml")).default;
        
        case "pl":
          return (await import("./vendor.pl.yml")).default;
        
        case "pt":
          return (await import("./vendor.pt.yml")).default;
        
        case "ru":
          return (await import("./vendor.ru.yml")).default;
        
        case "sv":
          return (await import("./vendor.sv.yml")).default;
        
        case "th":
          return (await import("./vendor.th.yml")).default;
        
        case "tr":
          return (await import("./vendor.tr.yml")).default;
        
        case "zh":
          return (await import("./vendor.zh.yml")).default;
        
        case "zh-Hans":
          return (await import("./vendor.zh-Hans.yml")).default;
        
        case "zh-Hant":
          return (await import("./vendor.zh-Hant.yml")).default;
        
        default:
          return {};
      }
   case "error":
      switch (lng) {
        case "ar":
          return (await import("./error.ar.yml")).default;
        
        case "cs":
          return (await import("./error.cs.yml")).default;
        
        case "ca":
          return (await import("./error.ca.yml")).default;
        
        case "da":
          return (await import("./error.da.yml")).default;
        
        case "de":
          return (await import("./error.de.yml")).default;
        
        case "el":
          return (await import("./error.el.yml")).default;
        
        case "en":
          return (await import("./error.en.yml")).default;
        
        case "es":
          return (await import("./error.es.yml")).default;
        
        case "fr":
          return (await import("./error.fr.yml")).default;
        
        case "it":
          return (await import("./error.it.yml")).default;
        
        case "ja":
          return (await import("./error.ja.yml")).default;
        
        case "ko":
          return (await import("./error.ko.yml")).default;
        
        case "ms":
          return (await import("./error.ms.yml")).default;
        
        case "nl":
          return (await import("./error.nl.yml")).default;
        
        case "no":
          return (await import("./error.no.yml")).default;
        
        case "pl":
          return (await import("./error.pl.yml")).default;
        
        case "pt":
          return (await import("./error.pt.yml")).default;
        
        case "ru":
          return (await import("./error.ru.yml")).default;
        
        case "sv":
          return (await import("./error.sv.yml")).default;
        
        case "th":
          return (await import("./error.th.yml")).default;
        
        case "tr":
          return (await import("./error.tr.yml")).default;
        
        case "zh":
          return (await import("./error.zh.yml")).default;
        
        case "zh-Hans":
          return (await import("./error.zh-Hans.yml")).default;
        
        case "zh-Hant":
          return (await import("./error.zh-Hant.yml")).default;
        
        default:
          return {};
      }
   case "pass":
      switch (lng) {
        case "ar":
          return (await import("./pass.ar.yml")).default;
        
        case "cs":
          return (await import("./pass.cs.yml")).default;
        
        case "ca":
          return (await import("./pass.ca.yml")).default;
        
        case "da":
          return (await import("./pass.da.yml")).default;
        
        case "de":
          return (await import("./pass.de.yml")).default;
        
        case "el":
          return (await import("./pass.el.yml")).default;
        
        case "en":
          return (await import("./pass.en.yml")).default;
        
        case "es":
          return (await import("./pass.es.yml")).default;
        
        case "fr":
          return (await import("./pass.fr.yml")).default;
        
        case "it":
          return (await import("./pass.it.yml")).default;
        
        case "ja":
          return (await import("./pass.ja.yml")).default;
        
        case "ko":
          return (await import("./pass.ko.yml")).default;
        
        case "ms":
          return (await import("./pass.ms.yml")).default;
        
        case "nl":
          return (await import("./pass.nl.yml")).default;
        
        case "no":
          return (await import("./pass.no.yml")).default;
        
        case "pl":
          return (await import("./pass.pl.yml")).default;
        
        case "pt":
          return (await import("./pass.pt.yml")).default;
        
        case "ru":
          return (await import("./pass.ru.yml")).default;
        
        case "sv":
          return (await import("./pass.sv.yml")).default;
        
        case "th":
          return (await import("./pass.th.yml")).default;
        
        case "tr":
          return (await import("./pass.tr.yml")).default;
        
        case "zh":
          return (await import("./pass.zh.yml")).default;
        
        case "zh-Hans":
          return (await import("./pass.zh-Hans.yml")).default;
        
        case "zh-Hant":
          return (await import("./pass.zh-Hant.yml")).default;
        
        default:
          return {};
      }
   case "passList":
      switch (lng) {
        case "ar":
          return (await import("./passList.ar.yml")).default;
        
        case "cs":
          return (await import("./passList.cs.yml")).default;
        
        case "ca":
          return (await import("./passList.ca.yml")).default;
        
        case "da":
          return (await import("./passList.da.yml")).default;
        
        case "de":
          return (await import("./passList.de.yml")).default;
        
        case "el":
          return (await import("./passList.el.yml")).default;
        
        case "en":
          return (await import("./passList.en.yml")).default;
        
        case "es":
          return (await import("./passList.es.yml")).default;
        
        case "fr":
          return (await import("./passList.fr.yml")).default;
        
        case "it":
          return (await import("./passList.it.yml")).default;
        
        case "ja":
          return (await import("./passList.ja.yml")).default;
        
        case "ko":
          return (await import("./passList.ko.yml")).default;
        
        case "ms":
          return (await import("./passList.ms.yml")).default;
        
        case "nl":
          return (await import("./passList.nl.yml")).default;
        
        case "no":
          return (await import("./passList.no.yml")).default;
        
        case "pl":
          return (await import("./passList.pl.yml")).default;
        
        case "pt":
          return (await import("./passList.pt.yml")).default;
        
        case "ru":
          return (await import("./passList.ru.yml")).default;
        
        case "sv":
          return (await import("./passList.sv.yml")).default;
        
        case "th":
          return (await import("./passList.th.yml")).default;
        
        case "tr":
          return (await import("./passList.tr.yml")).default;
        
        case "zh":
          return (await import("./passList.zh.yml")).default;
        
        case "zh-Hans":
          return (await import("./passList.zh-Hans.yml")).default;
        
        case "zh-Hant":
          return (await import("./passList.zh-Hant.yml")).default;
        
        default:
          return {};
      }
   case "linkDisplayer":
      switch (lng) {
        case "ar":
          return (await import("./linkDisplayer.ar.yml")).default;
        
        case "cs":
          return (await import("./linkDisplayer.cs.yml")).default;
        
        case "ca":
          return (await import("./linkDisplayer.ca.yml")).default;
        
        case "da":
          return (await import("./linkDisplayer.da.yml")).default;
        
        case "de":
          return (await import("./linkDisplayer.de.yml")).default;
        
        case "el":
          return (await import("./linkDisplayer.el.yml")).default;
        
        case "en":
          return (await import("./linkDisplayer.en.yml")).default;
        
        case "es":
          return (await import("./linkDisplayer.es.yml")).default;
        
        case "fr":
          return (await import("./linkDisplayer.fr.yml")).default;
        
        case "it":
          return (await import("./linkDisplayer.it.yml")).default;
        
        case "ja":
          return (await import("./linkDisplayer.ja.yml")).default;
        
        case "ko":
          return (await import("./linkDisplayer.ko.yml")).default;
        
        case "ms":
          return (await import("./linkDisplayer.ms.yml")).default;
        
        case "nl":
          return (await import("./linkDisplayer.nl.yml")).default;
        
        case "no":
          return (await import("./linkDisplayer.no.yml")).default;
        
        case "pl":
          return (await import("./linkDisplayer.pl.yml")).default;
        
        case "pt":
          return (await import("./linkDisplayer.pt.yml")).default;
        
        case "ru":
          return (await import("./linkDisplayer.ru.yml")).default;
        
        case "sv":
          return (await import("./linkDisplayer.sv.yml")).default;
        
        case "th":
          return (await import("./linkDisplayer.th.yml")).default;
        
        case "tr":
          return (await import("./linkDisplayer.tr.yml")).default;
        
        case "zh":
          return (await import("./linkDisplayer.zh.yml")).default;
        
        case "zh-Hans":
          return (await import("./linkDisplayer.zh-Hans.yml")).default;
        
        case "zh-Hant":
          return (await import("./linkDisplayer.zh-Hant.yml")).default;
        
        default:
          return {};
      }
   case "receiptDisplayer":
      switch (lng) {
        case "ar":
          return (await import("./receiptDisplayer.ar.yml")).default;
        
        case "cs":
          return (await import("./receiptDisplayer.cs.yml")).default;
        
        case "ca":
          return (await import("./receiptDisplayer.ca.yml")).default;
        
        case "da":
          return (await import("./receiptDisplayer.da.yml")).default;
        
        case "de":
          return (await import("./receiptDisplayer.de.yml")).default;
        
        case "el":
          return (await import("./receiptDisplayer.el.yml")).default;
        
        case "en":
          return (await import("./receiptDisplayer.en.yml")).default;
        
        case "es":
          return (await import("./receiptDisplayer.es.yml")).default;
        
        case "fr":
          return (await import("./receiptDisplayer.fr.yml")).default;
        
        case "it":
          return (await import("./receiptDisplayer.it.yml")).default;
        
        case "ja":
          return (await import("./receiptDisplayer.ja.yml")).default;
        
        case "ko":
          return (await import("./receiptDisplayer.ko.yml")).default;
        
        case "ms":
          return (await import("./receiptDisplayer.ms.yml")).default;
        
        case "nl":
          return (await import("./receiptDisplayer.nl.yml")).default;
        
        case "no":
          return (await import("./receiptDisplayer.no.yml")).default;
        
        case "pl":
          return (await import("./receiptDisplayer.pl.yml")).default;
        
        case "pt":
          return (await import("./receiptDisplayer.pt.yml")).default;
        
        case "ru":
          return (await import("./receiptDisplayer.ru.yml")).default;
        
        case "sv":
          return (await import("./receiptDisplayer.sv.yml")).default;
        
        case "th":
          return (await import("./receiptDisplayer.th.yml")).default;
        
        case "tr":
          return (await import("./receiptDisplayer.tr.yml")).default;
        
        case "zh":
          return (await import("./receiptDisplayer.zh.yml")).default;
        
        case "zh-Hans":
          return (await import("./receiptDisplayer.zh-Hans.yml")).default;
        
        case "zh-Hant":
          return (await import("./receiptDisplayer.zh-Hant.yml")).default;
        
        default:
          return {};
      }
   case "common":
      switch (lng) {
        case "ar":
          return (await import("./common.ar.yml")).default;
        
        case "cs":
          return (await import("./common.cs.yml")).default;
        
        case "ca":
          return (await import("./common.ca.yml")).default;
        
        case "da":
          return (await import("./common.da.yml")).default;
        
        case "de":
          return (await import("./common.de.yml")).default;
        
        case "el":
          return (await import("./common.el.yml")).default;
        
        case "en":
          return (await import("./common.en.yml")).default;
        
        case "es":
          return (await import("./common.es.yml")).default;
        
        case "fr":
          return (await import("./common.fr.yml")).default;
        
        case "it":
          return (await import("./common.it.yml")).default;
        
        case "ja":
          return (await import("./common.ja.yml")).default;
        
        case "ko":
          return (await import("./common.ko.yml")).default;
        
        case "ms":
          return (await import("./common.ms.yml")).default;
        
        case "nl":
          return (await import("./common.nl.yml")).default;
        
        case "no":
          return (await import("./common.no.yml")).default;
        
        case "pl":
          return (await import("./common.pl.yml")).default;
        
        case "pt":
          return (await import("./common.pt.yml")).default;
        
        case "ru":
          return (await import("./common.ru.yml")).default;
        
        case "sv":
          return (await import("./common.sv.yml")).default;
        
        case "th":
          return (await import("./common.th.yml")).default;
        
        case "tr":
          return (await import("./common.tr.yml")).default;
        
        case "zh":
          return (await import("./common.zh.yml")).default;
        
        case "zh-Hans":
          return (await import("./common.zh-Hans.yml")).default;
        
        case "zh-Hant":
          return (await import("./common.zh-Hant.yml")).default;
        
        default:
          return {};
      }
    case "intl-displaynames":
      switch (lng) {

        case "ar":
          return await import("@formatjs/intl-displaynames/locale-data/ar");        
        
        case "cs":
          return await import("@formatjs/intl-displaynames/locale-data/cs");        
        
        case "ca":
          return await import("@formatjs/intl-displaynames/locale-data/ca");        
        
        case "da":
          return await import("@formatjs/intl-displaynames/locale-data/da");        
        
        case "de":
          return await import("@formatjs/intl-displaynames/locale-data/de");        
        
        case "el":
          return await import("@formatjs/intl-displaynames/locale-data/el");        
        
        case "en":
          return await import("@formatjs/intl-displaynames/locale-data/en");        
        
        case "es":
          return await import("@formatjs/intl-displaynames/locale-data/es");        
        
        case "fr":
          return await import("@formatjs/intl-displaynames/locale-data/fr");        
        
        case "it":
          return await import("@formatjs/intl-displaynames/locale-data/it");        
        
        case "ja":
          return await import("@formatjs/intl-displaynames/locale-data/ja");        
        
        case "ko":
          return await import("@formatjs/intl-displaynames/locale-data/ko");        
        
        case "ms":
          return await import("@formatjs/intl-displaynames/locale-data/ms");        
        
        case "nl":
          return await import("@formatjs/intl-displaynames/locale-data/nl");        
        
        case "no":
          return await import("@formatjs/intl-displaynames/locale-data/no");        
        
        case "pl":
          return await import("@formatjs/intl-displaynames/locale-data/pl");        
        
        case "pt":
          return await import("@formatjs/intl-displaynames/locale-data/pt");        
        
        case "ru":
          return await import("@formatjs/intl-displaynames/locale-data/ru");        
        
        case "sv":
          return await import("@formatjs/intl-displaynames/locale-data/sv");        
        
        case "th":
          return await import("@formatjs/intl-displaynames/locale-data/th");        
        
        case "tr":
          return await import("@formatjs/intl-displaynames/locale-data/tr");        
        
        case "zh":
          return await import("@formatjs/intl-displaynames/locale-data/zh");        
        
        case "zh-Hans":
          return await import("@formatjs/intl-displaynames/locale-data/zh-Hans");        
        
        case "zh-Hant":
          return await import("@formatjs/intl-displaynames/locale-data/zh-Hant");        
        
        default:
          return {};
      }
    default:
      return {};
  }
}
