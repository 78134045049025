const commonLanguages: Record<string, string> = {
  ar: "العربية",
  ca: "Català",
  cs: "Čeština",
  da: "Dansk",
  de: "Deutsch",
  el: "Ελληνικά",
  en: "English",
  es: "Español",
  fr: "Français",
  ga: "Gaeilge",
  gd: "Gàidhlig",
  hy: "Հայերեն",
  id: "Bahasa Indonesia",
  is: "Icelandic",
  it: "Italiano",
  ja: "日本語",
  ko: "한국어",
  ku: "Kurdî",
  mk: "Македонски",
  mo: "Română",
  ms: "Bahasa Melayu",
  nl: "Nederlands",
  nn: "Norwegian Nynorsk",
  no: "Norsk",
  pl: "Polski",
  pt: "Português",
  ro: "Română",
  ru: "Pусский",
  sr: "Cрпски",
  sv: "Svenska",
  th: "ภาษาไทย",
  tr: "Türkçe",
  tk: "Türkmençe",
  tl: "Tagalog",
  zh: "中文",
  "zh-Hans": "简体中文",
  "zh-Hant": "繁體中文",
};

export function getLanguages(languages: string[]) {
  return languages.map((lng: string) => {
    let name: string | undefined;
    if (Intl && Intl.DisplayNames) {
      try {
        name = new Intl.DisplayNames([lng], { type: "language" }).of(lng);
      } catch (ex) {
        console.error(`can't find Intl.DisplayName for ${lng}`, ex);
      }
    }
    if (!name) {
      name = commonLanguages[lng] || commonLanguages[lng.split("-")[0]] || lng;
    }

    if (name) {
      name = name.charAt(0).toUpperCase() + name.slice(1);
    }

    return {
      lng,
      name,
    };
  });
}
